<template>
  <div class="product-page--create">
    <v-row class="d-flex justify-center " v-if="loadData">
      <v-col sm="12"><Loading class=""/></v-col>
    </v-row>
    <div v-if="!loadData">
      <v-row no-gutters>
        <v-col cols="12">
          <redirect class="mb-5" title="Upsell offers" route="website.apps.quantity.list" />
          <div class="page-header">
            <h2 class="page-title">{{ 'Update Offer' }}</h2>
          </div>
        </v-col>
      </v-row>
      <v-col cols="12">
        <Notification type="error" :messages="errors" v-if="errors.length > 0" />
      </v-col>
      <div>
        <v-row>
          <v-col cols="12" sm="4">
            <h4>Offer's name & message</h4>
          </v-col>
          <!-- <pre>{{ formData }}</pre> -->
          <v-col cols="12" sm="8" class="elevation-form">
            <InputDefault
              fieldName="Offer name*"
              :isValidate="true"
              label="Offer's name"
              :model="['formData', 'offerName']"
            />
            <small class="input-desc mt-2">
              Give your offer a name to manage easily or leave blank and we will automatically generate a name for you
            </small>
            <InputDefault
              fieldName="Offer title"
              :isValidate="true"
              label="Offer's title*"
              :model="['formData', 'offerTitle']"
            />
            <InputDefault
              fieldName="Offer Message"
              :isValidate="true"
              label="Offer message*"
              :model="['formData', 'offerMessage']"
            />
            <InputDefault
              fieldName="Gift Amout"
              :isValidate="true"
              label="Gift Amout*"
              :model="['formData', 'percentageDiscount']"
            />
          </v-col>
        </v-row>
        <v-col cols="12" class="my-2">
          <v-divider class=""></v-divider>
        </v-col>
        <v-row>
          <v-col cols="12" sm="4">
            <h4>Choose Target products</h4>
            <small
              >If customers add these products/ products in these collections to cart, this offer will pop up
            </small>
          </v-col>
          <v-col cols="12" sm="8" class="elevation-form">
            <v-radio-group v-model="formData.targetType" class="ml-3">
              <v-radio value="all" label="All product"> </v-radio>
              <v-radio label="Specific products" value="product">
                <template v-slot:label>
                  <div class="d-flex flex-column pt-20">
                    <span> Specific products </span>
                    <v-btn
                      v-if="formData.targetType === 'product'"
                      @click="configDialogSelection({ type: 'products', model: 'targetIds' })"
                    >
                      Select products</v-btn
                    >
                    <span v-if="formData.targetIds && formData.targetType === 'product'"
                      >{{ formData.targetIds.length }} product selected</span
                    >
                  </div>
                </template>
              </v-radio>
              <v-radio label=" Specific collections" value="collection">
                <template v-slot:label>
                  <div class="d-flex flex-column pt-20">
                    <span> Specific collections </span>
                    <v-btn
                      v-if="formData.targetType === 'collection'"
                      @click="configDialogSelection({ type: 'collections', model: 'targetIds' })"
                    >
                      Select Collection</v-btn
                    >
                    <span v-if="formData.targetIds && formData.targetType === 'collection'"
                      >{{ formData.targetIds.length }} Collection selected</span
                    >
                  </div>
                </template>
              </v-radio>
            </v-radio-group>
            <small
              class="text-error"
              v-if="formData.targetIds && formData.targetIds.length === 0 && formData.targetType !== 'all'"
              >Please select product(s)/collection(s).
            </small>
          </v-col>
        </v-row>
        <v-col cols="12" class="my-2">
          <v-divider class=""></v-divider>
        </v-col>

        <v-row>
          <v-col cols="12" sm="4">
            <h4 class="d-flex align-center">Choose Gifts</h4>
            <!-- <small class="input-desc mt-2">
              Offer discount and scarcity to motivate customers add Up-sell products to cart
            </small> -->
          </v-col>
          <v-col cols="12" sm="8" class="elevation-form">
            <v-btn @click="configDialogSelection({ type: 'collections', model: 'recommendIds' })">
              Select Collection</v-btn
            >
            <span v-if="formData && formData.recommendIds">{{ formData.recommendIds.length }} Collection selected</span>
          </v-col>

          <v-col cols="12" class="d-flex">
            <v-spacer></v-spacer>
            <v-btn outlined class="mr-3" @click="onCancel">Discard</v-btn>
            <v-btn :loading="isLoading" :disabled="isDisable" color="primary" @click="onSubmit">
              {{ mode === 'create' ? 'Create' : 'Save' }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <v-col cols="12" class="my-3">
        <v-divider class=""></v-divider>
      </v-col>
      <v-dialog :scrollable="false" v-model="dialog" width="900" content-class="l-dialog">
        <SelectionItems
          :model="configSelectionItem.model"
          :type="configSelectionItem.type"
          @returnData="getDataSelected"
          :dialog.sync="dialog"
          :dataSelected="configSelectionItem.data"
          :isSuperShow="true"
        />
      </v-dialog>
      <BeforeLeavePage />
    </div>
  </div>
</template>

<script>
import Loading from '@/components/Loading.vue';
import SelectionItems from '../component/SelectionItems';
import { offerApi } from '@/apis/offers';
import { convertIdsToArrays } from '@/helpers/convertData';
import { required, minLength, maxLength, between, email } from 'vuelidate/lib/validators';

export default {
  components: {
    SelectionItems,
    Loading,
  },
  data() {
    return {
      loadData: false, // là load data khi qua màn Update
      mode: 'create',
      errors: [],
      configSelectionItem: {
        type: 'products',
        model: 'targetIds',
        data: [],
      },
      dialog: false,
      isDisable: false,
      isLoading: null,
      formData: {
        activated: true,
        offerMessage: 'free gift on orders over {{variable}}',
        offerType: 'gift',
        priority: 0,
        offerName: '',
        offerTitle: null,
        recommendIds: [], // product collection ids ,
        recommendType: 'collection', //product, collection, sameCollection, relevantCollection
        targetIds: [],
        targetType: 'all', //all, product, collection
        store: '',
        enableDiscount: false,
        percentageDiscount: 0,
      },
    };
  },
  validations: {
    formData: {
      offerName: {
        required,
      },
      offerTitle: {
        required,
      },
      offerMessage: {
        required,
      },
      percentageDiscount: {
        required,
      },
    },
  },
  mounted() {
    this.onLoadData();
  },
  methods: {
    async onLoadData() {
      this.isLoading = true;
      try {
        let res = await offerApi.getById(this.$route.params.id);
        let offer = res.data.offer;
        let [collectionRecommendIds, collectionTargetIds, productRecommendIds, productTargetIds] = await Promise.all([
          convertIdsToArrays('collection', offer.collectionRecommendIds),
          convertIdsToArrays('collection', offer.collectionTargetIds),
          convertIdsToArrays('product', offer.productRecommendIds),
          convertIdsToArrays('product', offer.productTargetIds),
        ]);
        this.dataDefault = {
          collectionRecommendIds,
          collectionTargetIds,
          productRecommendIds,
          productTargetIds,
        };
        this.formData = {
          activated: offer.activated,
          offerMessage: offer.offerMessage,
          offerType: offer.offerType,
          priority: offer.priority,
          offerName: offer.offerName,
          offerTitle: offer.offerTitle,
          recommendIds: [], // product collection ids ,
          recommendType: offer.recommendType, //product, collection, sameCollection, relevantCollection
          targetIds: [],
          targetType: offer.targetType, //all, product, collection
          enableDiscount: offer.enableDiscount,
          percentageDiscount: offer.percentageDiscount,
          discountData: offer.discountData,
        };
        if (this.formData.recommendType === 'product' || this.formData.recommendType === 'collection') {
          this.formData.recommendIds = this.dataDefault[`${this.formData.recommendType}RecommendIds`];
          this.dataDefault[`${this.formData.recommendType}RecommendIds`] = this.dataDefault[
            `${this.formData.recommendType}RecommendIds`
          ];
        }
        if (this.formData.targetType === 'product' || this.formData.targetType === 'collection') {
          this.formData.targetIds = this.dataDefault[`${this.formData.targetType}TargetIds`];
          this.dataDefault[`${this.formData.targetType}TargetIds`] = this.dataDefault[
            `${this.formData.targetType}TargetIds`
          ];
        }
        console.log(this.formData);
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    onCancel() {},
    async onSubmit() {
      this.$v.formData.$touch();
      if (!this.$v.formData.$invalid) {
        let convertData = Object.assign({}, this.formData);
        convertData.targetIds = convertData.targetIds.map(item => item._id);
        convertData.recommendIds = convertData.recommendIds.map(item => item._id);
        convertData.store = this.$store.state.auth.accountInfo.storeId;
        if (this.formData.targetType === 'product' || this.formData.targetType === 'collection') {
          convertData[`${this.formData.targetType}TargetIds`] = convertData.targetIds;
        }
        if (this.formData.recommendType === 'product' || this.formData.recommendType === 'collection') {
          convertData[`${this.formData.recommendType}RecommendIds`] = convertData.recommendIds;
        }
        delete convertData.recommendIds;
        delete convertData.targetIds;
        try {
          this.isLoading = true;
          let response = await offerApi.update(this.$route.params.id, convertData);
          this.isLoading = false;
        } catch (error) {
          console.log(error);
        }
      }
    },
    configDialogSelection(data) {
      this.dialog = true;
      this.configSelectionItem = Object.assign({}, data);
      if (this.formData.targetIds && data.model === 'targetIds') {
        this.configSelectionItem.data = this.formData.targetIds;
      }
      if (this.formData.recommendIds && data.model === 'recommendIds') {
        this.configSelectionItem.data = this.formData.recommendIds;
      }
    },
    getDataSelected(data, model) {
      this.formData[model] = JSON.parse(JSON.stringify(data));
    },
  },
  watch: {
    'formData.recommendType': function(n, o) {
      if (o) {
        this.formData.recommendIds = [];
      }
      if (
        (o && this.$route.params.id !== undefined && this.formData.recommendType === 'collection') ||
        this.formData.recommendType === 'product'
      ) {
        this.formData.recommendIds = this.dataDefault[`${this.formData.recommendType}RecommendIds`];
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
